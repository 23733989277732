const getDiscountPricing = (price, productType, discountPercentage) => {
  let finalPrice = parseFloat(price)

  if (discountPercentage > 0) {
    const discountAmount = (finalPrice * discountPercentage) / 100
    finalPrice -= discountAmount
  } else if (Pura.cart.enable_sitewide_discount) {
    const discountTypes = Pura.cart.discount_types
      ? Pura.cart.discount_types.split(', ')
      : null

    const siteWideAmount = (finalPrice * Pura.cart.discount_amount) / 100
    if (!discountTypes || discountTypes.includes(productType)) {
      finalPrice -= siteWideAmount
    }
  }

  return Math.round(finalPrice * 100) / 100
}

export { getDiscountPricing }

import $ from 'cash-dom'
import { debounce } from '../utilities/toolkit'
import { searchThumbnail } from '../templates/search-thumbnail'
import { searchProductsKlevu, klevuSettings } from '../resources/klevu-search'
import { searchProducts } from '../resources/shopify-search'
import { getKlevuBadge, getKlevuCategoryBadge } from './klevu-badges'

class SearchPanel {
  constructor() {
    this.panel = $('#search-panel')
    this.input = this.panel.find('#search-input')
    this.popularSearches = this.panel.find(
      '[data-element="popular-searches-panel"]'
    )
    this.resultsPanel = this.panel.find('[data-element="search-results-panel"]')
    this.searchResults = this.panel.find('[data-content="search-results"]')
    this.searchButton = this.panel.find(
      '[data-action="view-all-search-results"]'
    )
    this.pageResult = this.panel.find('[data-action="view-page-result"]')
    this.searchCount = this.panel.find('[data-action="total-results"]')
    this.mobileMenuButton = $('#mobile-menu-button')
    this.mobileSearchButton = $('#mobile-search-button')
    this.lastSearch = ''
    this.open = false

    this.bindUI()
  }

  bindUI() {
    let me = this

    $('[data-action="show-search-panel"]').on('click', (e) => {
      e.preventDefault()

      const cachedSearchQuery = this.input.val()
      if (cachedSearchQuery.length) {
        me.submitSearch(cachedSearchQuery)
      }

      e.target.setAttribute('aria-expanded', 'true')
      this.show()
    })

    $('[data-action="hide-search-panel"]').on('click', (e) => {
      e.preventDefault()
      this.hide()
    })

    $('[data-action="toggle-search-panel"]').on('click', (e) => {
      e.preventDefault()

      const cachedSearchQuery = this.input.val()
      if (cachedSearchQuery.length) {
        me.submitSearch(cachedSearchQuery)
      }

      this.input.focus()
      if (this.open) {
        this.hide()
        return
      }
      this.show()
    })

    $('[data-element="search-input"]').on(
      'keyup',
      debounce(function (e) {
        e.preventDefault()

        // Close if escape key
        if (e.key == 'Escape') {
          me.hide()
          return
        }

        let query = e.target.value.trim().replace('’', "'")
        if (query == me.lastSearch) return
        me.lastSearch = query

        if (query.length == 0) {
          me.hideResults()
          return
        }
        me.submitSearch(query)
      }, 350)
    )

    $('[data-action="search-for-query"]').on('click', (e) => {
      e.preventDefault()
      let query = $(e.currentTarget).attr('data-query')
      this.input.val(query)
      this.submitSearch(query)
    })
  }

  show() {
    this.panel.attr('open', 'true')
    this.mobileSearchButton.attr('aria-expanded', 'true')
    this.open = true
    this.mobileMenuButton.hide()

    setTimeout(() => {
      this.input.focus()
    }, 500)
  }

  hide() {
    this.panel.attr('open', 'false')
    this.mobileSearchButton.attr('aria-expanded', 'false')
    this.input.blur()
    this.open = false
    this.mobileMenuButton.show()
    $('[aria-controls="search-panel"][aria-expanded="true"]').focus()
    $('[aria-controls="search-panel"]').attr('aria-expanded', 'false')
  }

  showResultsKlevu(result, searchClickManager) {
    let badge = null
    let categoryBadges = []
    let html = ''

    if (result === false) {
      html = '<p>no results found</p>'
    } else {
      // Build the result
      for (let item of result) {
        badge = getKlevuBadge(item)
        categoryBadges = getKlevuCategoryBadge(item)
        html += searchThumbnail(item, badge, categoryBadges)
      }
    }

    this.popularSearches.hide()
    this.popularSearches.attr('aria-hidden', 'true')

    this.searchResults.html(html)
    this.resultsPanel.show()
    this.resultsPanel.removeAttr('aria-hidden')

    // Send Analytic Events to Klevu
    $('[data-element="search-items"]').each(function () {
      $(this).on('click', function (e) {
        const searchId = this.getAttribute('data-id')
        const searchGroupId = this.getAttribute('data-group-id')
        searchClickManager(searchId, searchGroupId)
      })
    })
  }

  showTotalResults(query, totalResults) {
    // reset view state
    this.pageResult.addClass('hidden')
    this.searchResults.removeClass('hidden')
    this.searchButton.removeClass('hidden')

    const term = query.replace('&', '+').trim()
    if (totalResults > 0 && totalResults !== undefined) {
      this.searchCount.html(totalResults)
      this.searchButton.removeClass('hidden')
      this.searchResults.removeClass('hidden')
      this.pageResult.addClass('hidden')
      this.searchButton.attr('href', '/search?q=' + term)
    } else {
      this.searchButton.addClass('hidden')
    }

    // url redirects
    const kmc = JSON.parse(window.localStorage.getItem('klevu-kmc-data'))

    if (kmc) {
      const urlMappings = kmc.maps.klevu_keywordUrlMap
      for (let settings of urlMappings) {
        let keywords = settings.keywords

        for (let keyword of keywords) {
          query = query.toLocaleLowerCase()
          if (keyword === query) {
            this.pageResult.removeClass('hidden')
            this.searchResults.addClass('hidden')
            this.searchButton.addClass('hidden')
            this.pageResult.attr('href', settings.url)
          }
        }
      }
    }
  }

  showResults(results) {
    this.popularSearches.hide()
    this.popularSearches.attr('aria-hidden', 'true')

    this.searchResults.html(results)
    this.resultsPanel.show()
    this.resultsPanel.removeAttr('aria-hidden')
  }

  hideResults() {
    let me = this
    this.searchResults.removeClass('show-results')
    setTimeout(function () {
      me.resultsPanel.hide()
      me.resultsPanel.attr('aria-hidden', 'true')
      me.popularSearches.show()
      me.popularSearches.removeAttr('aria-hidden')
    }, 500)
  }

  submitSearch(query) {
    let me = this
    let searchClickManager

    this.searchResults.removeClass('show-results')

    if (Pura.isKlevuEnabled) {
      let totalResults

      klevuSettings()

      searchProductsKlevu(query).then(function (result) {
        const searchResult = result.queriesById('search')
        searchClickManager = searchResult.getSearchClickSendEvent()

        totalResults = result.queriesById('search').meta.totalResultsFound
        result = result.queriesById('search')?.records
        me.showTotalResults(query, totalResults)

        const buildElevarEcommerceItem = (item) => ({
          id: item.sku,
          name: item.name,
          brand: item.brand,
          variant: item.variant_title || 'Default',
          price: item.price,
          product_id: item.itemGroupId,
          variant_id: item.id,
        })

        if (result == null || result.length < 1) {
          me.showResultsKlevu(false)
        } else {
          me.showResultsKlevu(result, searchClickManager)
          const items = result.map((product) => {
            const item = { ...product }
            if (item.selling_plan_allocation) {
              item.title += ' Subscription'
            }
            return {
              ...buildElevarEcommerceItem(item),
            }
          })
          const userProperties = Pura.customer
            ? {
                customer_email: Pura.customer.email,
                customer_first_name: Pura.customer.first_name,
                customer_id: Pura.customer.key,
                customer_last_name: Pura.customer.last_name,
                visitor_type: 'logged_in',
              }
            : {
                visitor_type: 'guest',
              }
          window.ElevarDataLayer.push({
            event: 'dl_view_search_results',
            user_properties: userProperties,
            ecommerce: {
              currencyCode: 'USD',
              actionField: {
                'list': '/header/search',
              },
              impressions: items,
            },
          })
        }
        setTimeout(function () {
          me.searchResults.addClass('show-results')
        }, 100)
      })
    } else
      searchProducts(query).then(function (body) {
        body = body.trim()
        if (body == null || body.length < 10) {
          me.showResults('<p>no results found</p>')
        } else {
          me.showResults(body)
        }
        setTimeout(function () {
          me.searchResults.addClass('show-results')
        }, 100)
      })
  }
}

const searchPanel = new SearchPanel()

export { searchPanel }

import $ from 'cash-dom'
import { getCookie, setCookie } from '../utilities/cookies'
import { showNewsletterPopup } from './newsletter-popup.ts'

const checkForHomepagePopup = (time) => {
  const cookie = getCookie('signup-popup')
  if (!cookie && Pura.template.name === 'index') {
    showNewsletterPopup(time)
  }
}

const preventBodyScroll = () => {
  $('body').addClass('overflow-hidden')
}

const enableBodyScroll = () => {
  $('body').removeClass('overflow-hidden')
}

const showConsent = getCookie('consent-show')
const time = $("[data-section-id='popup']").attr('data-section-settings') * 1000

if (!Pura.isSubscriptionPortal && Pura.dev.osano_enabled && !showConsent) {
  $(document).ready(function () {
    const isOsanoAvailable = () => {
      const acceptPrivacy = $('.osano-cm-accept')
      const closeBtn = $('.osano-cm-info-dialog-header__close')
      const managePrivacy = $('.osano-cm-manage')
      const popupDelay = 5000

      if (acceptPrivacy.length === 0) {
        setCookie('consent-show', 'true', 365)
        checkForHomepagePopup(time + popupDelay)
      } else {
        $('.osano-cm-buttons').attr('style', 'display: flex !important')
      }

      $(acceptPrivacy).click(function () {
        setCookie('consent-show', 'true', 365)
        checkForHomepagePopup(popupDelay)
      })

      $(managePrivacy).click(function (e) {
        e.stopPropagation()
        preventBodyScroll()

        const saveBtn = $('.osano-cm-save')
        const poweredByLink = $('.osano-cm-powered-by__link')

        $(document).on('click.outsidePrivacyPanel', function (e) {
          if (
            !$(e.target).closest(saveBtn).length &&
            !$(e.target).closest(closeBtn).length
          ) {
            enableBodyScroll()
            $(document).off('click.outsidePrivacyPanel')
          }
        })

        $(poweredByLink).attr('target', '_blank')
        $(saveBtn).click(function () {
          enableBodyScroll()
          setCookie('consent-show', 'true', 365)
          checkForHomepagePopup(popupDelay)
          $(document).off('click.outsidePrivacyPanel')
        })
      })

      $(closeBtn).click(function () {
        enableBodyScroll()
        $(document).off('click.outsidePrivacyPanel')
      })
    }

    // we set an observer to change banner 1 display immediately on load
    const osanoObserver = new MutationObserver(function (mutations, obs) {
      const osanoModal = $('.osano-cm-dialog')
      if (osanoModal.length) {
        isOsanoAvailable()
        obs.disconnect()
      }
    })
    osanoObserver.observe(document.body, {
      childList: true,
      subtree: false,
    })
  })
} else {
  checkForHomepagePopup(time)
}

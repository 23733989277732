import $ from 'cash-dom'
import { Auth } from 'aws-amplify'

const hubSignOut = async () => {
  try {
    await Auth.signOut()
  } catch (err) {
    console.error('Error during full logout:', err.message)
  }
}

$('a[href="/account/logout"]').on('click', (event) => {
  event.preventDefault()

  hubSignOut()
})

export { hubSignOut }

import {
  renderGroupGiftBag,
  renderGroupGiftBagAddon,
  groupHasGiftBag,
} from './cart-group-gift-bag'

const currencyFormat = window.Pura.currency.format

// renders an entire group and passes in the
// parent item and all items in the cart
const renderGroup = (item, items) => {
  const groupId = item.properties._pura_group_id
  let hideQuantity = false
  let hideRemove = false
  let bundleHomeCar = false
  let excludeGiftBagSkus = Pura.cart.exclude_gift_bag || false

  // get all items in the group
  const groupItems = items.filter(
    (cartItem) => cartItem.properties?._pura_group_id == groupId
  )

  if (excludeGiftBagSkus) {
    const skus = excludeGiftBagSkus.split(',').map((sku) => sku.trim())
    excludeGiftBagSkus = groupItems.some((groupItem) =>
      skus.includes(groupItem.sku)
    )
  }
  const hasGiftBag = groupHasGiftBag(groupItems)

  let borderClass = 'border-b'
  // clear the border class if we're going to show the addon
  if (!hasGiftBag && Pura.cart.show_gift_bag) {
    borderClass = ''
  }
  let html = `<div class="cart-item group hover:bg-cream transition-medium px-gutter ${borderClass} border-neutral-300" data-component="cart-item" data-group-id="${groupId}" data-key="${groupItems[0].key}" >`

  // sort group items by display order
  groupItems.sort((a, b) => {
    if (a.properties._pura_display_order < b.properties._pura_display_order) {
      return -1
    }
    return 1
  })

  // check parent item for hide quantity
  if (groupItems[0].properties._pura_hide_quantity) {
    hideQuantity = true
  }

  if (groupItems[0].properties._pura_hide_remove) {
    hideRemove = true
  }

  // check parent item for bundling the home and car display
  if (groupItems[0].properties._pura_bundle_home_car) {
    bundleHomeCar = true
  }
  // loop through the items to render the templates
  for (let i = 0; i < groupItems.length; ++i) {
    html += renderGroupItem(groupItems[i], bundleHomeCar, i)
  }

  // render gift bag item
  if (hasGiftBag) {
    html += renderGroupGiftBag(groupId, groupItems)
  }

  // render quantity and remove button
  html += cartGroupButtonsTemplate(item, hideQuantity, hideRemove)

  // Closing tag for main group
  html += '</div>'

  const groupDeviceCount = getNumberOfDevicesInGroup(groupItems)

  // add gift bag as option
  if (
    Pura.cart.show_gift_bag &&
    !hasGiftBag &&
    groupDeviceCount < 2 &&
    !excludeGiftBagSkus
  ) {
    // render gift bag add on
    // pass in group ID and quantity
    html += renderGroupGiftBagAddon(
      groupId,
      item.quantity,
      item.properties._pura_packing_id
    )
  }

  return html
}

const getHeading = (item) => {
  let heading = item.properties._pura_cart_heading

  if (
    (!heading && item.product_type == 'Box') ||
    (item.product_type == 'Car Set' && item.id !== 40729365446765)
  ) {
    heading = `${item.title} includes:`
  }
  return heading
}

const getBadge = (item) => {
  if (item.properties._pura_cart_badge) {
    return item.properties._pura_cart_badge
  }

  return item.sku == '900-00858' ||
    item.sku == '900-02342' ||
    item.sku == '900-02640'
    ? 'Car'
    : 'Home'
}

const getPrice = (item, currencyFormat) => {
  let price = (item.discounted_price / 100).toFixed(2)
  price = currencyFormat.replace('{{amount}}', price).replace('.00', '')
  return item.discounted_price == 0 ? 'FREE' : price
}

const getShippingFrequency = (item) => {
  if (item.selling_plan_allocation) {
    const shippingFrequency = item.selling_plan_allocation.selling_plan.name

    return shippingFrequency.includes(',')
      ? shippingFrequency.split(',')[0]
      : shippingFrequency
  }
  return ''
}

const renderGroupItem = (item, bundleHomeCar, i) => {
  let html = ''

  if (item.sku == '900-02205') return ''

  if (
    item.product_type == 'Box' ||
    item.product_type == 'Car Set' ||
    item.properties._pura_cart_heading != null
  ) {
    const heading = getHeading(item)
    const badge = getBadge(item)

    if (!bundleHomeCar || (bundleHomeCar && i === 0)) {
      html += groupHeaderTemplate(heading, badge, bundleHomeCar)
    }
  }

  const price = getPrice(item, currencyFormat)
  let { image, title } = getItemTitlesAndImages(item)
  const comparePrice = getComparePrice(item)
  const shippingFrequency = getShippingFrequency(item)

  if (item.id === 41182546198637 && item.properties._pura_group_id) {
    title = `Volcano <br/> Linens & Surf`
  }
  if (item.id === 40729365446765 && item.properties._pura_group_id) {
    title = `Volcano`
  }

  html += groupItemTemplate(
    item,
    title,
    image,
    price,
    comparePrice,
    shippingFrequency
  )

  return html
}

const groupHeaderTemplate = (heading, badge, mergedDisplay) =>
  `<div class="flex justify-between gap-4 pt-6" >
    <p class="text-body-sm mb-0">
      ${heading}
    </p>
    ${
      mergedDisplay
        ? `<div class="flex flex-row gap-2"><span class="badge-home">Home</span> <span class="badge-car">Car</span></div>`
        : `<span class="badge-${badge?.toLowerCase()}">${badge}</span>`
    }
  </div>`

const groupItemTemplate = (
  product,
  title,
  image,
  price,
  comparePrice,
  shippingFrequency
) => `
  <div class="bundleItem group flex mt-4
      overflow-hidden border-neutral-300" >
      <div class="block relative">
        <img src="${image}"
          alt="${title}"
          class="w-[100px] h-[100px] object-cover" />
      </div>
      <div class="flex-1 flex flex-col items-start justify-center ml-12">
        <p class="text-caption-semibold mb-[2px]">${title}</p>
        ${
          shippingFrequency != null
            ? `<p class="shipping-message text-caption mb-[2px] ">${shippingFrequency}</p>`
            : ``
        }
        ${
          product.properties?._pura_cart_message != null
            ? `<p class="cart-message text-caption mb-2">${product.properties._pura_cart_message}</p>`
            : ``
        }
        ${
          price == 'FREE' && product.selling_plan_allocation
            ? `<p class="shipping-message text-caption text-neutral-700 mb-[2px] ">
                Today: FREE
              </p>
              <p class="text-caption" >
                Next Shipment:
                  <span class="line-through opacity-60 mx-2" >
                    $${(
                      product.selling_plan_allocation.compare_at_price / 100
                    ).toFixed(2)}
                  </span>
                  $${(
                    product.selling_plan_allocation.per_delivery_price / 100
                  ).toFixed(2)}
              </p>`
            : `<p class="text-caption mb-1 ">
                ${
                  comparePrice
                    ? `<span class='line-through fs-12 opacity-50 mr-2'>
                      ${comparePrice}</span>`
                    : ``
                }
                ${price}
              </p>`
        }

      </div>
    </div>`

const cartGroupButtonsTemplate = (item, hideQuantity, hideRemove) =>
  `<div class="flex items-center justify-between mt-7 pb-5" >
    <div class="flex items-center" >
      ${
        hideQuantity
          ? `<div></div>`
          : `<p class="text-body-sm fw-4 mb-0 mr-4">Quantity</p>
          <div data-component="quantity" class="rounded border border-neutral-300 fs-14 w-[77px]"
            style="min-height: 26px;" >
            <button ${
              item.quantity === 1 ? `disabled class="text-smoke" ` : ''
            }aria-label="decrement quantity"
              data-action="decrement" >
              <svg class="svg-decrement" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><title>decrement</title><path fill="currentColor" d="M368 224H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h352c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg>
            </button>
            <input type="number" name="quantity" value="${
              item.quantity
            }" min="1"
              data-element="quantity-input" class="fs-12" >
            <button aria-label="increment quantity"
              data-action="increment">
              <svg class="svg-increment" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><title>increment</title><path fill="currentColor" d="M368 224H224V80c0-8.84-7.16-16-16-16h-32c-8.84 0-16 7.16-16 16v144H16c-8.84 0-16 7.16-16 16v32c0 8.84 7.16 16 16 16h144v144c0 8.84 7.16 16 16 16h32c8.84 0 16-7.16 16-16V288h144c8.84 0 16-7.16 16-16v-32c0-8.84-7.16-16-16-16z"></path></svg>
            </button>
          </div>`
      }
    </div>

     ${
       hideRemove === false
         ? `<div>
      <button
        aria-label="remove item from cart"
        data-action="remove-item"
          class="button-underline-sm mb-2 fs-12">
          Remove set
        </button>`
         : ``
     }
    </div>
  </div>

  <div class="hidden border-t border-neutral-300 pt-4 pb-5" >
    <p class="text-body-sm" >
      Add promo code at checkout
    </p>
  </div>`

const getItemTitlesAndImages = (item) => {
  // set title and image
  let title = item.product_title
  let image = item.image?.replace('.jpg', '_200x.jpg')

  // swap out device
  if (item.sku == '1DB' || item.sku == '10-1DB-09') {
    title = 'Pura 3'
    image =
      'https://cdn.shopify.com/s/files/1/0023/0021/5405/files/diffuser_device_200x.jpg'

    // Car device
  } else if (item.sku == '900-00858') {
    title = 'Pura Car'
    image = 'https://cdn.shopify.com/s/files/1/0023/0021/5405/files/car-nav.png'

    // Pura 4 device
  } else if (item.sku == '900-00761' || item.sku == '810-00032') {
    title = 'Pura 4'
    image =
      'https://cdn.shopify.com/s/files/1/0023/0021/5405/files/1_2c403087-e5c9-4f6c-8e38-32089d828880_200x.jpg'
  } else if (item.sku == '900-01037') {
    title = 'Pura Plus - White'
    image =
      'https://cdn.shopify.com/s/files/1/0023/0021/5405/files/pura-plus-white_200x.webp'
  } else if (item.sku == '900-02336') {
    title = 'Pura Plus - Black'
    image =
      'https://cdn.shopify.com/s/files/1/0023/0021/5405/files/pura-plus-black_200x.webp'
  } else if (item.sku == '900-02640') {
    title = 'Pura Car Pro'
    image =
      'https://cdn.shopify.com/s/files/1/0023/0021/5405/files/pura-car-pro_200x.webp'
  }

  return { title, image }
}

const getComparePrice = (item, price) => {
  let comparePrice = false

  // if price is discounted because of script or automating discount
  if (item.original_price > item.discounted_price) {
    comparePrice = item.original_price
  }

  // if discounted because of subscription discount
  if (
    item.selling_plan_allocation &&
    item.selling_plan_allocation.compare_at_price > item.discounted_price
  ) {
    // selling_plan_allocation.compare_at_price is original OTP price
    // and will be bigger than item.original_price
    // so it displayes a larger discount
    comparePrice = item.selling_plan_allocation.compare_at_price
  }

  // if there's a differnet display price, like FREE
  // set compare price to original price
  // this overrides all other logic
  if (item.properties._pura_display_price != null) {
    comparePrice = item.discounted_price
  }

  // format the display price
  if (comparePrice) {
    comparePrice = (comparePrice / 100).toFixed(2)
    comparePrice = currencyFormat.replace('{{amount}}', comparePrice)
    comparePrice = comparePrice.replace('.00', '')
  }

  return comparePrice
}

const getNumberOfDevicesInGroup = (groupItems) => {
  let count = 0
  let item
  for (let i = 0; i < groupItems.length; ++i) {
    item = groupItems[i]
    if (
      item.sku == '1DB' ||
      item.sku == '10-1DB-09' ||
      item.sku == '900-00858' ||
      item.sku == '900-00761' ||
      item.sku == '810-00032'
    ) {
      // increase count if we have pura home or car device SKU
      ++count
    }
  }
  return count
}

export { renderGroup }

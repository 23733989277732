import $ from 'cash-dom'
import { cart } from './cart'
import { addVariantToCart } from '../resources/shopify-cart'
import { cartUpsellItem } from '../templates/cart-upsell-item'
import { cartHasProduct } from '../utilities/toolkit'
import {
  hasCarDevice,
  hasCarFragrance,
  hasCarSet,
  hasHomeFragrance,
  hasHomeDevice,
  hasCarFragranceSub,
  hasHomeFragranceSub,
} from './cart-toolkit'
import { productPricing } from './product-pricing'

const cartUpsellComponent = $('[data-element="cart-upsell-item-panel"]')
const cartUpsellItemsList = $('[data-content="cart-upsell-items"]')

const hideCartUpsells = () => {
  cartUpsellComponent.addClass('hidden')
}

const showCartUpsells = () => {
  cartUpsellComponent.removeClass('hidden')
}

const renderCartUpsells = (products) => {
  const html = products
    .map((product) => {
      const { oneTimePurchasePrice, comparePrice } = productPricing(product)
      const isDiscounted = comparePrice > oneTimePurchasePrice
      return cartUpsellItem(product, oneTimePurchasePrice, isDiscounted)
    })
    .join('')

  if (html) {
    cartUpsellItemsList.html(html)
    showCartUpsells()
  } else {
    hideCartUpsells()
  }
}

const buildUniqueProductList = (data, products) => {
  const uniqueProducts = products
    .filter((product) => !cartHasProduct(data, product.id))
    .slice(0, 4)

  return uniqueProducts
}

const getCartUpsells = (data) => {
  if (!Pura.cart.upsell_enabled) return

  const carDevice = hasCarDevice(data)
  const homeDevice = hasHomeDevice(data)
  const carFragrance = hasCarFragrance(data)
  const carFragranceSub = hasCarFragranceSub(data)
  const carSet = hasCarSet(data)
  const homeFragrance = hasHomeFragrance(data)
  const homeFragranceSub = hasHomeFragranceSub(data)

  let upsellItems
  if (
    carDevice &&
    (carFragrance || carFragranceSub) &&
    homeDevice &&
    (!homeFragrance || !homeFragranceSub)
  ) {
    upsellItems = Pura.cart.upsell_items.upsell_items
  } else if (
    carDevice &&
    (carFragrance || carFragranceSub) &&
    (homeFragrance || homeFragranceSub) &&
    !homeDevice
  ) {
    upsellItems = Pura.cart.upsell_items.upsell_items
  } else if (carSet || carDevice || carFragrance || carFragranceSub) {
    upsellItems = Pura.cart.car_upsell_items.upsell_items
  } else {
    upsellItems = Pura.cart.upsell_items.upsell_items
  }
  if (upsellItems) {
    const list = buildUniqueProductList(data, upsellItems)
    renderCartUpsells(list)
  }
}

cartUpsellItemsList.on('change', (e) => {
  const itemInput = e.target.closest('[data-element="quantity-input"]')
  const decrementButton = $(itemInput).prev()

  if (!itemInput || !decrementButton || !decrementButton.is('button')) {
    return
  }

  const itemMin = parseInt($(itemInput).min, 10) || 1
  const itemQuantity = parseInt($(itemInput).val(), 10) || 1

  if (itemQuantity <= itemMin) {
    decrementButton.prop('disabled', true)
    decrementButton.addClass('text-smoke')
  } else {
    decrementButton.prop('disabled', false)
    decrementButton.removeClass('text-smoke')
  }
})

cartUpsellItemsList.on('click', (e) => {
  if (!e.target.closest('[data-add-variant-to-cart]')) return

  const cartItemComponent = e.target.closest('[data-component="cart-item"]')
  const fullPrice = e.target.getAttribute('data-properties-pura-full-price')
  const properties = {}
  const propertyData = e.target.getAttribute('data-properties')
  const variantId = e.target.getAttribute('data-add-variant-to-cart')

  if (propertyData) {
    properties[propertyData] = 'true'
  }

  if (fullPrice) {
    properties._pura_full_price = fullPrice.replace('$', '')
    properties._pura_full_price_currency = 'USD'
  }

  const itemQuantity =
    parseInt(
      $(cartItemComponent).find('[data-element="quantity-input"]').val(),
      10
    ) || 1

  addVariantToCart(variantId, '', itemQuantity, properties).then((data) => {
    cart.update(data)
  })
})

export { getCartUpsells }

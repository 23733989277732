import $ from 'cash-dom'
import { Auth } from 'aws-amplify'
import { createFormStateManager } from '../utilities/form-manager'
import { emailSignIn } from './sign-in'

const resetCodeFormManager = createFormStateManager({
  formSelector: '#send-reset-code-form',
  defaultText: 'Submit',
})

const sendResetCode = async (email: string) => {
  try {
    sessionStorage.setItem('resetEmail', email)
    const res = await Auth.forgotPassword(email)

    if (res) {
      resetCodeFormManager.setVerified()
    }
  } catch (e) {
    console.log('Password Reset Failed:', e)
    resetCodeFormManager.setFailure(e.message)
  }
}

const hubForgotPassword = () => {
  const currentUrl = window.location.href

  if (currentUrl.includes('/account/activate')) {
    return
  }

  window.location.href = '/account/login?reset_email=true'
}

const hubForgotPasswordFailure = (message: string) => {
  resetCodeFormManager.setFailure(message)
}

$('#send-reset-code-form').on('submit', function (e) {
  e.preventDefault()
  resetCodeFormManager.setLoading('Sending reset code...')

  let email = String($(e.target).find('#send-reset-email').val()).trim()
  sendResetCode(email)
})

const resetPasswordFormManager = createFormStateManager({
  formSelector: '#reset-password-form',
  defaultText: 'Reset password',
})

const resetPassword = async (email: string, code: string, password: string) => {
  try {
    sessionStorage.setItem('resetEmail', email)
    const res = await Auth.forgotPasswordSubmit(email, code, password)
    if (res) {
      resetPasswordFormManager.setVerified('Password reset successful.')
      emailSignIn(email, password)
    }
  } catch (e) {
    resetPasswordFormManager.setFailure(e.message)
  }
}

const hubForgotPasswordSubmit = () => {}

const hubForgotPasswordSubmitFailure = (message: string) => {
  resetPasswordFormManager.setFailure(message)
}

$('#reset_customer_password').on('submit', function () {
  const shopifyResetPassword = createFormStateManager({
    formSelector: '#reset_customer_password',
    defaultText: 'Reset password',
  })

  shopifyResetPassword.setLoading('Resetting password...')
})

$('#reset-password-form').on('submit', function (e) {
  e.preventDefault()
  resetPasswordFormManager.setLoading()

  const form = $(e.target)
  const email = String(form.find('#reset-email').val()).trim()
  const code = String(form.find('#reset-code').val()).trim()
  const password = String($('#reset-new-password').val()).trim()

  resetPassword(email, code, password)
})

let resetEmailField = $('#reset-email')
if (resetEmailField) {
  const email = sessionStorage.getItem('resetEmail')
  if (email) {
    resetEmailField.val(email)
    resetEmailField.trigger('change')
  }
}

export {
  hubForgotPassword,
  hubForgotPasswordFailure,
  hubForgotPasswordSubmit,
  hubForgotPasswordSubmitFailure,
  sendResetCode,
  resetPassword,
}

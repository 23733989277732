import $ from 'cash-dom'
import { Auth } from 'aws-amplify'
import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth'
import { setCookie } from '../utilities/cookies'

const setRedirectInfo = () => {
  setCookie('login_redirect', window.location.href, 1)
}

$('[data-action="login-with-google"]').on('click', () => {
  setRedirectInfo()
  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Google,
    customState: 'pura-oauth',
  })
})

$('[data-action="login-with-facebook"]').on('click', () => {
  setRedirectInfo()

  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Facebook,
  })
})

$('[data-action="login-with-apple"]').on('click', () => {
  setRedirectInfo()

  Auth.federatedSignIn({
    provider: CognitoHostedUIIdentityProvider.Apple,
  })
})

import { cart } from './cart'
import { addVariantToCart, updateCartItem } from '../resources/shopify-cart'

const cartItems = document.querySelector('[data-element="cart-items"]')

const addGiftBagToGroup = (e) => {
  if (!e.target.closest('[data-action="add-gift-bag-to-group"]')) return

  const groupId = e.target.getAttribute('data-group-id')
  const quantity = e.target.getAttribute('data-quantity')
  const packingId = e.target.getAttribute('data-packing-id')

  let properties = {
    _pura_group_id: groupId,
    _pura_group_role: 'child',
    _pura_packing_id: packingId,
    _pura_full_price: (Pura.cart.gift_bag_price / 100).toFixed(2),
    _pura_full_price_currency: 'USD',
  }

  const giftBagVariant = Pura.environment.production
    ? '40743150125165'
    : '42304984809572'

  addVariantToCart(giftBagVariant, null, quantity, properties).then(
    function () {
      cart.updateAndShow()
    }
  )
}

const removeGiftBagFromGroup = (e) => {
  if (!e.target.closest('[data-action="remove-gift-bag-from-group"]')) return

  const key = e.target.getAttribute('data-key')

  updateCartItem(key, 0).then(function (data) {
    cart.update(data)
  })
}

cartItems.addEventListener('click', addGiftBagToGroup)
cartItems.addEventListener('click', removeGiftBagFromGroup)

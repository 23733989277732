import $ from 'cash-dom'
import { sendResetCode, resetPassword } from './reset'
import { getURLParam } from '../utilities/toolkit'

let activatePassword: string
let activateEmail: string

const activateOnShopify = async (
  password: string,
  id: string,
  token: string
) => {
  try {
    password = encodeURIComponent(password)

    const body = `form_type=activate_customer_password&utf8=%E2%9C%93&customer%5Bpassword%5D=${password}&customer%5Bpassword_confirmation%5D=${password}&token=${token}&id=${id}`

    const response = await fetch('/account/activate', {
      method: 'POST',
      credentials: 'same-origin',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: body,
    })

    if (!response.ok) {
      throw new Error(response.statusText)
    }
    return response
  } catch (error) {
    console.error('activate error', error)
    throw error
  }
}

$('#account-activation-form').on('submit', function (e) {
  e.preventDefault()

  activatePassword = this.querySelector('#activate-password').value
  const token = this.querySelector('[name="token"]').value
  const id = this.querySelector('[name="id"]').value
  activateEmail = decodeURIComponent(
    getURLParam('activate_email') as string
  ).trim()

  activateOnShopify(activatePassword, id, token).then((res) => {
    if (!res.ok) return
    setTimeout(() => {
      sendResetCode(activateEmail)
    }, 200)

    $('#activate-password-section').addClass('hidden')
    $('#activate-code-section').removeClass('hidden')
  })
})

$('#account-verify-code-form').on('submit', function (e) {
  e.preventDefault()

  const code = $('#verification-code').val() as string
  resetPassword(activateEmail, code, activatePassword)
})

import $ from 'cash-dom'

const countdownIntervals = {}

const startCountdown = (element, layoutType = 'default') => {
  const $element = $(element)
  const dataTime = $element.attr('data-time')
  const endTime = new Date(`${dataTime}-07:00`).getTime()

  const updateCountdownDisplay = () => {
    const currentTime = new Date().getTime()
    const remainingTime = Math.floor((endTime - currentTime) / 1000)

    if (remainingTime <= 0 || Number.isNaN(remainingTime)) {
      clearInterval(countdownIntervals[$element.data('index')])
      $element.addClass('hidden')
      return
    }

    const days = Math.floor(remainingTime / 86400)
    const hours = Math.floor((remainingTime % 86400) / 3600)
    const minutes = Math.floor((remainingTime % 3600) / 60)
    const seconds = Math.floor(remainingTime % 60)

    if (layoutType === 'inline') {
      $element.text(
        `${days > 0 ? `${days}d :` : ''} ${hours}h : ${minutes}m : ${seconds}s`
      )
    } else if (layoutType === 'stacked') {
      $element
        .find('[data-time-unit="days"]')
        .text(days.toString().padStart(2, '0'))
      $element
        .find('[data-time-unit="hours"]')
        .text(hours.toString().padStart(2, '0'))
      $element
        .find('[data-time-unit="minutes"]')
        .text(minutes.toString().padStart(2, '0'))
      $element
        .find('[data-time-unit="seconds"]')
        .text(seconds.toString().padStart(2, '0'))
    }
  }

  updateCountdownDisplay()

  countdownIntervals[$element.data('index')] = setInterval(
    updateCountdownDisplay,
    1000
  )
}

const endCountdown = () => {
  Object.values(countdownIntervals).forEach((interval) =>
    clearInterval(interval)
  )
}

const $sectionCountdown = $('[data-element="section-countdown"]')

if ($sectionCountdown.length) {
  $sectionCountdown.each((index, el) => {
    const $element = $(el)
    $element.data('index', index)
    startCountdown(el, 'stacked')
  })
}

export { startCountdown, endCountdown }

import { renderCartItem } from '../templates/cart-item'
import { renderGroup } from '../templates/cart-group'

const getTemplateName = (item) => {
  if (item.properties?._pura_group_id != null) {
    return 'group'
  }
  return 'cart-item'
}

const generateCartItemsHTML = (cartData) => {
  const { items } = cartData
  let item
  let html = ''
  const renderedGroups = new Set()

  for (let i = 0, n = items.length; i < n; ++i) {
    item = items[i]

    if (!item.properties?._pura_hidden_in_cart) {
      const templateName = getTemplateName(item)

      if (templateName === 'group') {
        const groupId = item.properties._pura_group_id
        if (!renderedGroups.has(groupId)) {
          html += renderGroup(item, items)
          renderedGroups.add(groupId)
        }
      } else {
        html += renderCartItem(item)
      }
    }
  }

  return html
}

export { generateCartItemsHTML }

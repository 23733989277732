import $ from 'cash-dom'
import { updateURLParam } from '../utilities/toolkit'
import { getDiscountPercentage } from './product-pricing'

const productForm = $('[data-component="product-form"]')
const addToCartButton = productForm.find('[data-element="add-to-cart-button"]')
const subscriptionInputs = productForm.find(
  '[data-element="subscription-input"], #sub-interval-frequency'
)
const oneTimeInfoPanel = productForm.find('[data-element="one-time-info"]')
const productPriceElement = $('[data-content="product-price"]')

const salePrice = $(productPriceElement).attr('data-sale')
const oneTimePrice = $(productPriceElement).attr('data-otp')
const fullPrice = $(productPriceElement).attr('data-compare')
const discountTypes =
  Pura.cart.discount_types?.split(',').map((s) => s.trim()) || false

const hasDiscountTag =
  Pura.product?.tags.some((tag) => tag.startsWith('discount_')) ||
  Pura.productForm?.includedProducts?.some(
    (includedProduct) => getDiscountPercentage(includedProduct?.tags) > 0
  ) ||
  false

// Product form OTP and SUB options
$(
  '[data-component="product-form"] [name="pdp-purchase-type"], [data-component="product-form"] [name="included-products-purchase-type"]'
).on('change', (e) => {
  const value = e.target.getAttribute('value')
  // Subscription product
  if (value == 'SUB') {
    updateURLParam('variant', null)
    addToCartButton.attr('data-state', 'subscribe')
    subscriptionInputs.prop('disabled', false)
    oneTimeInfoPanel.addClass('hidden')

    // Change display price to SUB with OTP crossed out
    productPriceElement.html(
      `${salePrice}
        <span class="line-through fs-16 opacity-50">
          ${fullPrice}
        </span>`
    )
    return
  }

  // Normal product
  updateURLParam('variant', Pura.product?.variants[0]?.id)
  addToCartButton.attr('data-state', 'add-to-cart')
  subscriptionInputs.prop('disabled', true)
  oneTimeInfoPanel.removeClass('hidden')

  if (
    hasDiscountTag ||
    (Pura.cart.enable_sitewide_discount &&
      (!discountTypes || discountTypes?.includes(Pura.product.type)))
  ) {
    productPriceElement.html(
      `${oneTimePrice}<span class="line-through fs-16 opacity-50">${fullPrice}</span>`
    )
  } else {
    productPriceElement.html(fullPrice)
  }
})

import $ from 'cash-dom'
type FormState = 'idle' | 'loading' | 'disabled' | 'verified' | 'failure'

interface FormStateManagerOptions {
  formSelector?: string
  defaultLoadingText?: string
  defaultVerifiedText?: string
  defaultFailureText?: string
  defaultText?: string
}

const createFormStateManager = ({
  formSelector,
  defaultLoadingText = 'Loading...',
  defaultVerifiedText = 'Success!',
  defaultFailureText = 'Something went wrong!',
  defaultText,
}: FormStateManagerOptions = {}) => {
  let state: FormState = 'idle'
  let message: string | null = null
  let hideErrorTimeout: NodeJS.Timeout | null = null

  const getState = () => state
  const setLoading = (customText?: string) => {
    state = 'loading'
    message = customText || defaultLoadingText
    updateUI()
  }

  const setDisabled = () => {
    state = 'disabled'
    updateUI()
  }

  const reset = () => {
    state = 'idle'
    message = null
    updateUI()
  }

  const setVerified = (msg?: string) => {
    state = 'verified'
    message = msg || defaultVerifiedText
    updateUI()
  }

  const setFailure = (msg?: string) => {
    state = 'failure'
    const sanitizedMessage =
      typeof msg === 'string'
        ? msg.replace(/ExpiredCodeException:?/gi, '').trim()
        : defaultFailureText

    message = sanitizedMessage || defaultFailureText
    updateUI()

    if (hideErrorTimeout) clearTimeout(hideErrorTimeout)
    hideErrorTimeout = setTimeout(() => {
      const errorField = $(`${formSelector} .error-message`)
      if (errorField.length) {
        errorField.text('').hide()
      }
    }, 5000)
  }

  const updateUI = () => {
    const submitButton = $(`${formSelector} button[type="submit"]`)
    const errorField = $(`${formSelector} .error-message`)

    if (submitButton.length) {
      submitButton.prop(
        'disabled',
        state === 'loading' ||
          state === 'failure' ||
          state === 'disabled' ||
          state === 'verified'
      )

      let buttonText = defaultText || 'Submit'
      if (state === 'loading') {
        buttonText = message || defaultLoadingText
      } else if (state === 'verified') {
        buttonText = message || defaultVerifiedText
      }
      submitButton.text(buttonText)
    }

    if (errorField.length) {
      if (state === 'failure' && message) {
        errorField.text(message).show()
      } else {
        errorField.text('').hide()
      }
    }
  }

  return {
    getState,
    reset,
    setLoading,
    setDisabled,
    setVerified,
    setFailure,
  }
}

export { createFormStateManager }

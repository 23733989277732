const stage = !window.Pura?.environment.production

const baseUrl = stage
  ? 'https://staging.trypura.io/mobile/api'
  : 'https://trypura.io/mobile/api'

const checkMigration = async (
  email: string,
  password: string
): Promise<Response> => {
  try {
    const response = await fetch(`${baseUrl}/check_migration_needed`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email, password }),
    })

    if (!response.ok) {
      throw new Error(`Failed to check migration: ${response.statusText}`)
    }

    return response
  } catch (error) {
    console.error('Error in checkMigration:', error)
    throw error
  }
}

export { checkMigration }

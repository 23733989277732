import $ from 'cash-dom'
import { emailSignIn } from './sign-in.ts'
import { Auth } from 'aws-amplify'
import { createFormStateManager } from '../utilities/form-manager.ts'
import { getURLParam } from '../utilities/toolkit.js'

const signUpFormManager = createFormStateManager({
  formSelector: '#account-sign-up-form',
  defaultText: 'Sign Up',
})
const verifyFormManager = createFormStateManager({
  formSelector: '#account-verify-form',
  defaultText: 'Verify Email',
})

const createUser = async (
  firstName: string,
  lastName: string,
  email: string,
  password: string
) => {
  const data = {
    username: email,
    password,
    attributes: {
      name: `${firstName} ${lastName}`,
      given_name: firstName,
      family_name: lastName,
    },
  }
  try {
    await Auth.signUp(data)
    if (window.Pura?.accountPanel) {
      window.Pura.accountPanel.showVerifyPanel(email, password)
      signUpFormManager.reset()
    }
  } catch (e) {
    signUpFormManager.setFailure(e.message)
  }
}

const confirmAccount = async (
  email: string,
  password: string,
  code: string
): Promise<void> => {
  try {
    await Auth.confirmSignUp(email, code)
    await emailSignIn(email, password)
    verifyFormManager.setVerified()
  } catch (error) {
    verifyFormManager.setFailure(error.message)
  }
}

const resendConfirmationCode = async (email: string): Promise<void> => {
  try {
    await Auth.resendSignUp(email)
    console.log('Verification code resent to email:', email)
  } catch (error) {
    console.error('Error resending verification code:', error.message || error)
  }
}

const hubSignUp = () => {}

const hubSignUpFailure = (message: string) => {
  signUpFormManager.setFailure(message)
}

const hubResendSignUp = () => {}

const hubResendSignUpFailure = () => {}

$('#account-sign-up-form').on('submit', function (e) {
  e.preventDefault()
  signUpFormManager.setLoading('Creating Account...')

  const form = $(e.target)
  const firstName = (form.find('#sign-up-first-name').val() as string).trim()
  const lastName = (form.find('#sign-up-last-name').val() as string).trim()
  const email = (form.find('#sign-up-email').val() as string).trim()
  const password = (form.find('#sign-up-password').val() as string).trim()

  createUser(firstName, lastName, email, password)
})

$('#account-verify-form').on('submit', function (e) {
  e.preventDefault()
  verifyFormManager.setLoading('Verifying Account...')

  const form = $(e.target)
  const email = (form.find('#verification-email').val() as string).trim()
  const password = (form.find('#verification-password').val() as string).trim()
  const code = (form.find('#verification-code').val() as string).trim()

  confirmAccount(email, password, code)
})

$('[data-action="resend-verification-code"]').on('click', function (e) {
  e.preventDefault()

  const email = $('#verification-email').val() as string
  resendConfirmationCode(email)

  $('#account-verify-form [data-content="response-message"]')
    .html('Check your email for a new code.')
    .removeClass('hidden')
})

$('[data-action="reset-password-resend-code"]').on('click', function (e) {
  e.preventDefault()

  const resetEmailValue = $('#reset-email').val() as string
  if (resetEmailValue) {
    resendConfirmationCode(resetEmailValue)
  }

  $('#password-reset-panel [data-content="response-message"]')
    .html('Check your email for a new code.')
    .removeClass('hidden')
})

if (location.pathname == '/account/register') {
  let email = getURLParam('email')
  let code = getURLParam('code')

  // Auto submit form if we have both
  if (email != null && code != null) {
    setTimeout(function () {
      confirmAccount(email, '', code)
    }, 600)
  }

  if (email != null) {
    $('#verification-email').val(email)
    $('#verification-email').trigger('change')
  }
  if (code != null) {
    $('#verification-code').val(code)
    $('#verification-code').trigger('change')
  }
}

export {
  createUser,
  resendConfirmationCode,
  hubSignUp,
  hubSignUpFailure,
  hubResendSignUp,
  hubResendSignUpFailure,
}

import $ from 'cash-dom'

$('#sticky-add-to-cart').on('click', function (event) {
  const $collapsedSection = $('.collapsed-section')
  const $subscribeTextElement = $('.subscribe-text')
  const $mobileStickyAtc = $('.mobile-sticky-atc')

  if (
    $collapsedSection.css('display') === 'none' ||
    $collapsedSection.height() === 0
  ) {
    event.preventDefault()
    $collapsedSection.css({
      display: 'block',
      transition: 'height 0.3s ease-in-out',
      height: 'auto',
    })

    if (!$('.overlay').length) {
      $('body').append('<div class="overlay"></div>')
    }

    $('.overlay').css({
      position: 'fixed',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      backgroundColor: 'rgba(20, 23, 26, 0.4)',
      zIndex: 15,
      backdropFilter: 'blur(2px) saturate(120%)',
    })

    $('body').addClass('overflow-hidden')

    $mobileStickyAtc.addClass('custom-radius border-[#CEC6C2] border-t')
    $subscribeTextElement.html('Subscribe &nbsp;|&nbsp; ')
  } else {
    closeStickySection()
  }
})
$(function () {
  const $decrementBtn = $('.decrement')
  const $incrementBtn = $('.increment')
  const $subscribeText = $('.subscribe-text')

  $decrementBtn.on('click', function (e) {
    e.preventDefault()
  })
  $incrementBtn.on('click', function (e) {
    e.preventDefault()
  })
  $('.close-mobile-sticky-atc').on('click', function () {
    closeStickySection()
  })
  $(document).on('click', '.overlay', function () {
    closeStickySection()
  })

  $('.collapsed-section li').on('click', function (event) {
    const $radioButton = $(this).find('input[type="radio"]')

    if ($radioButton.length) {
      $radioButton.prop('checked', true).trigger('change')

      const selectedValue = $radioButton.val()
      if (selectedValue === 'SUB') {
        $subscribeText.html('Subscribe &nbsp;|&nbsp; ')
      } else if (selectedValue === 'OTP') {
        $subscribeText.html('Add to cart &nbsp;|&nbsp; ')
      } else {
        $addToCartButton.text('Add to Cart')
      }
    }
  })
})

function closeStickySection() {
  const $collapsedSection = $('.collapsed-section')
  const $subscribeTextElement = $('.subscribe-text')
  const $mobileStickyAtc = $('.mobile-sticky-atc')

  if ($collapsedSection.length) {
    $collapsedSection.css({
      height: '0',
      transition: 'height 0.2s ease-in-out',
      display: 'none',
    })

    $('.overlay').remove()
    $('body').removeClass('overflow-hidden')

    $mobileStickyAtc.removeClass('custom-radius border-[#CEC6C2] border-t')
    $subscribeTextElement.html('Add to cart &nbsp;|&nbsp; ')
  }
}

import $ from 'cash-dom'

const pauseVideos = (modal) => {
  const videos = modal.find('video')
  if (!videos.length) return

  videos.each((index, video) => {
    video.pause()
  })
}

$('modal-panel').on('click', (e) => {
  if ($(e.target).is('modal-overlay')) {
    pauseVideos($(e.currentTarget))
  }
})
$('[data-action="hide-modal"]').on('click', function () {
  const modal = $(this).closest('modal-panel')
  pauseVideos(modal)
})

$('[data-element="audio-toggle"]').on('click', function () {
  const $videoElement = $(this).closest('[data-element="video"]')
  const video = $videoElement.find('video')[0]

  const swiperContainer = $videoElement.closest('swiper-container')
  const swiper = swiperContainer[0]?.swiper

  const $mutedIcon = $(this).find('.muted')
  const $unmutedIcon = $(this).find('.unmuted')

  video.muted = !video.muted

  if (video.muted) {
    $mutedIcon.removeClass('hidden')
    $unmutedIcon.addClass('hidden')

    if (swiper && swiper.autoplay) {
      swiper.autoplay.start()
    }
  } else {
    if (swiper && swiper.autoplay) {
      swiper.autoplay.stop()
    }

    $mutedIcon.addClass('hidden')
    $unmutedIcon.removeClass('hidden')
  }
})

const buildLazyLoad = () => {
  const lazyVideos = document.querySelectorAll('.lazy-video')

  if ('IntersectionObserver' in window) {
    const videoObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const video = entry.target
          const videoSrc = video.getAttribute('data-src')
          const isLoaded = video.getAttribute('src')
          if (isLoaded) return

          const source = video.querySelector('source')
          const sourceSrc = source.getAttribute('data-src')

          if (videoSrc && sourceSrc) {
            video.src = videoSrc
            source.src = sourceSrc
            video.load()
            videoObserver.unobserve(video)
          } else {
            console.error(
              'data-src attribute is missing for video or source',
              video
            )
          }
        }
      })
    })

    lazyVideos.forEach((video) => {
      videoObserver.observe(video)
    })
  } else {
    lazyVideos.forEach((video) => {
      const videoSrc = video.getAttribute('data-src')
      const isLoaded = video.getAttribute('src')
      const source = video.querySelector('source')
      const sourceSrc = source.getAttribute('data-src')

      if (isLoaded) return
      if (videoSrc && sourceSrc) {
        video.src = videoSrc
        source.src = sourceSrc
        video.load()
      } else {
        console.error(
          'data-src attribute is missing for video or source',
          video
        )
      }
    })
  }
}

document.addEventListener('DOMContentLoaded', () => {
  if (!window.Pura.isInApp) {
    buildLazyLoad()
  }
})

const disableVideoFullScreen = () => {
  const $videos = $('[data-element="video"] video')

  $videos.each((index, video) => {
    const $video = $(video)
    $video.controls = false

    const $playButton = $(`
      <button
        aria-label="Play video"
        style="top: 10px; right: 10px"
        class="absolute leading-0 z-9 text-button inner-circle !shadow-none flex-center bg-white bg-opacity-50 text-black rounded-full fs-16 !w-[40px] !h-[40px] pl-1 m-1"
        data-element="video-play-toggle">
        <span class="muted">
          <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <path id="Vector" d="M1 2.2859C1.00012 2.06668 1.05625 1.85112 1.16307 1.65968C1.26989 1.46825 1.42385 1.30728 1.61035 1.19205C1.79685 1.07683 2.0097 1.01117 2.2287 1.0013C2.4477 0.991439 2.66559 1.0377 2.8617 1.13569L28.2899 13.8498C28.5033 13.9568 28.6828 14.121 28.8082 14.3241C28.9336 14.5273 29 14.7613 29 15C29 15.2387 28.9336 15.4727 28.8082 15.6759C28.6828 15.879 28.5033 16.0432 28.2899 16.1502L2.8617 28.8643C2.66559 28.9623 2.4477 29.0086 2.2287 28.9987C2.0097 28.9888 1.79685 28.9232 1.61035 28.8079C1.42385 28.6927 1.26989 28.5318 1.16307 28.3403C1.05625 28.1489 1.00012 27.9333 1 27.7141L1 2.2859Z" stroke="#1D1B1B" stroke-width="1.5" stroke-linecap="square"/>
            </svg>
        </span>
      </button>
    `)

    $video.closest('[data-element="video"]').append($playButton)
    video.addEventListener('webkitbeginfullscreen', () => {
      video.webkitExitFullscreen()
    })

    video.addEventListener('webkitendfullscreen', () => {
      video.controls = false
      $playButton.show()
    })

    $playButton.on('click', () => {
      const videoSrc = $video.attr('data-src')
      const source = $video.find('source')
      const sourceSrc = source.attr('data-src')
      if (videoSrc && sourceSrc) {
        $video.attr('src', videoSrc)
        source.attr('src', sourceSrc)

        video.load()
        video.play()
        $playButton.hide()
      } else {
        console.error(
          'data-src attribute is missing for video or source',
          video
        )
      }
    })
  })
}

if (window.Pura.isInApp) {
  disableVideoFullScreen()
}

export { disableVideoFullScreen, buildLazyLoad }

import $ from 'cash-dom'
import { showSelectionPanel, hideSelectionPanel } from './BYS-selection-panel'
import { addVariantToCart } from '../resources/shopify-cart'
import { cart } from './cart'

class QuickView {
  constructor() {
    const _ = this

    _.selectedProduct = {}

    _.bindUI()
  }

  bindUI() {
    const _ = this

    $('[data-action="show-quick-view"]').on('click', (e) => {
      const productButton = e.target.closest('[data-action="show-quick-view"]')
      const productId = productButton.getAttribute('data-product-id')

      _.selectedProduct = JSON.parse($(`#product-data-${productId}`).html())

      showSelectionPanel(_.selectedProduct, 1)
      _.setUpAddToCart()
    })
  }

  setUpAddToCart() {
    const _ = this

    $('[data-action="add-selected-product-to-cart"]').off('click')

    $('[data-action="add-selected-product-to-cart"]').on('click', (e) => {
      const properties = {
        _pura_full_price: (_.selectedProduct.price / 100).toFixed(2),
        _pura_full_price_currency: 'USD'
      }
      const purchaseType = $('#product-selection-panel [name="purchase-type"]:checked').val()
      const subFrequency = purchaseType === 'SUB' ?
        $('#product-selection-panel #sub-interval-frequency').val() : null
      const variantId = _.selectedProduct.variant_id
      let selling_plan = ''
  
      if (purchaseType === 'SUB') {
        let intervalFrequency = subFrequency || 1
        intervalFrequency = parseInt(intervalFrequency) - 1
        selling_plan = _.selectedProduct.selling_plans[intervalFrequency].id
      }
  
      if (_.selectedProduct.isFinalSale) {
        properties._pura_cart_is_final_sale = 'true'
      }

      if (!_.selectedProduct.selling_plans) {
        properties._pura_has_no_subscription = 'true'
      }

      addVariantToCart(variantId, selling_plan, 1, properties).then(() => {
        hideSelectionPanel()
        cart.updateAndShow()
      }).catch(e => {
        console.log(e)
      })
    })
  }
}

const quickView = new QuickView()

import { reviewComponent } from '../components/review-component'

const productGrid = (
  item,
  price,
  comparePrice,
  badge,
  categoryBadges,
  type,
  atcButton,
  reviews,
  showPrice,
  showReviews
) =>
  `<div class="w-100 px-2 md:px-3 lg:px-4"
     data-id=${item.id}
     data-group-id=${item.itemGroupId}
     ${(() => {
       let dataElement
       if (type === 'productRecs') {
         dataElement = 'data-element="recommended-products"'
       } else {
         dataElement = `data-element="collection-products" data-collectionID=${collectionID}`
       }
       return dataElement
     })()}
     >
  <a
    href="${item.url}"
    class="product-thumbnail"
    data-tag="${item.name}">
    <div
      class="product-thumbnail-image-container overflow-hidden">
      ${
        item.custom_media_display != null
          ? `<video
          class="custom-media-display object-cover lazy-video"
          autoPlay="true"
          muted="true"
          loop="true"
          playsInline="true"
          poster="${item.image}"
          data-src="${item.custom_media_display.replace(
            'https //',
            'https://'
          )}"
          width="600"
         height="600"
         alt="${item.name}"
        >
        <source
        data-src="${item.custom_media_display.replace('https //', 'https://')}"
        type="video/mp4"></video>
      <img
        alt="${item.name}"
        src="${item.image}"
        width="600"
        height="600"
        loading="lazy"
        class="product-thumbnail-main-image absolute-fill object-cover transition-medium hidden">`
          : `<img
        alt="${item.name}"
        src="${item.image}"
        width="600"
        height="600"
        loading="lazy"
        class="product-thumbnail-main-image absolute-fill object-cover transition-medium">`
      }

        ${
          item.imageHover != null && item.custom_media_display == null
            ? `<img
                alt="${item.name} - ingredients view"
                src="${item.imageHover}"
                width="600"
                height="600"
                loading="lazy"
                class="product-thumbnail-hover-image absolute-fill object-cover transition-medium opacity-0 blur-sm">`
            : ``
        }
        ${
          badge != null
            ? `<div class="${
                badge === 'Sold out' || badge === 'Notify me'
                  ? 'badge-oos'
                  : 'badge'
              }"> ${badge}</div>`
            : ``
        }

    </div>
    <div class="flex flex-wrap gap-x-2 gap-y-[2px] md:gap-y-2">
    ${
      categoryBadges != null &&
      categoryBadges
        .map(
          (category) =>
            `<span class="badge-${category
              ?.toLowerCase()
              .replace(/\s+/g, '-')} capitalize-first">${
              category.includes('Tech') ? category : category.replace('-', ' ')
            }</span>`
        )
        .join(' ')
    }
    </div>
    <div class="flex justify-between w-100 mt-1">
        <p
          aria-label="fragrance name"
          class="text-body mb-0 pr-2"
          data-tag="${item.name}">
          ${item.name}
        </p>
        ${
          showPrice
            ? (() => {
                const comparePriceSpan =
                  comparePrice != null
                    ? `<span aria-label="original price is: $${comparePrice}" class="line-through opacity-50 pl-2">$${comparePrice}</span>`
                    : ``
                return `<p class="text-right text-body mb-0" data-tag="${price.toFixed(
                  2
                )}">
                  ${price === 0 ? 'FREE' : `$${price.toFixed(2)}`}
                  ${comparePriceSpan}
                </p>`
              })()
            : ``
        }
    </div>
    <p
      class="text-caption opacity-60 mb-0"
      data-tag="${item.brand}">
      ${item.brand}
    </p>

    ${reviews != null && showReviews ? reviewComponent(item) : ``}

    </a>
    ${
      atcButton != null && badge !== 'Sold out' && badge !== 'Notify me'
        ? `<button
        data-action="add-variant-to-cart"
        data-variant="${item.id}"
        class="button w-100"> Add </button>`
        : ``
    }
    </div>
    `
export { productGrid }

import $ from 'cash-dom'
import { getCookie, setCookie } from '../utilities/cookies'
import { slideClosed } from '../utilities/animations'
import { updateCartAttribute } from '../resources/shopify-cart'
import { endCountdown, startCountdown } from './countdown-timer'

const checkAnnouncementHeight = (announcementBar) => {
  let height = 0
  if (announcementBar) {
    height = announcementBar.offsetHeight || 0
  }

  document.documentElement.style.setProperty(
    '--announcement-height',
    `${height}px`
  )
}

const disableAnnouncementBar = (type) => {
  setCookie(`${type}-disable-announcement-bar`, true, 4)
}

const enableAnnouncementBar = () => {
  updateCartAttribute('announcement', '').then(() => {
    window.location.reload()
  })
}

window.addEventListener('resize', () => {
  checkAnnouncementHeight()
})

const adjustHeightDisplay = (height) => {
  const isLargerHeight = height === 'larger'
  const heroHeight = $('[data-hero-height]')[0]
  const heroHeightValue = heroHeight
    ? heroHeight.getAttribute('data-hero-height')
    : '100'

  const classesToAdd = isLargerHeight
    ? `h-[calc(${heroHeightValue}svh-92px)] md:h-[calc(${heroHeightValue}svh-120px)]`
    : `h-[calc(${heroHeightValue}svh-60px)] md:h-[calc(${heroHeightValue}svh-80px)]`

  const classesToRemove = isLargerHeight
    ? `h-[calc(${heroHeightValue}svh-60px)] md:h-[calc(${heroHeightValue}svh-80px)]`
    : `h-[calc(${heroHeightValue}svh-92px)] md:h-[calc(${heroHeightValue}svh-120px)]`

  $('[data-component="video-hero"]')
    .addClass(classesToAdd)
    .removeClass(classesToRemove)

  $('[data-component="video-hero"] swiper-container, .lcp-content')
    .addClass(classesToAdd)
    .removeClass(classesToRemove)

  $('.video-fullscreen video')
    .addClass(classesToAdd)
    .removeClass(classesToRemove)
}

function shouldDisplayAnnouncement(type) {
  return (
    (type === 'app' && window.Pura.isInApp) ||
    (type === 'web' && !window.Pura.isInApp)
  )
}

document.addEventListener('DOMContentLoaded', () => {
  setTimeout(checkAnnouncementHeight, 100)

  const announcementBars = [
    document.querySelector('[data-element="announcement-bar"]'),
    document.querySelector('[data-element="announcement-bar-app"]'),
  ]

  announcementBars.forEach((announcementBar) => {
    if (!announcementBar) return

    const type = announcementBar.getAttribute('data-type')
    const countdowns = announcementBar.querySelectorAll(
      '[data-element="countdown"]'
    )
    const disabledAnnouncementBar = getCookie(
      `${type}-disable-announcement-bar`
    )
    if (!disabledAnnouncementBar && shouldDisplayAnnouncement(type)) {
      announcementBar.classList.remove('hidden')
      adjustHeightDisplay('larger')

      checkAnnouncementHeight(announcementBar)
      if (countdowns.length) {
        countdowns.forEach((countdown) => {
          startCountdown(countdown, 'inline')
        })
      }

      const closeButton = announcementBar.querySelector(
        '[data-action="hide-announcement-bar"]'
      )

      if (closeButton) {
        closeButton.addEventListener('click', () => {
          announcementBar.classList.add('hidden')
          adjustHeightDisplay('smaller')
          disableAnnouncementBar(type)
          slideClosed(announcementBar)
          endCountdown()
        })
      }
    }
  })
})
// This is so we can reset the announcement bar
// from the browser console if we want to
const Pura = window.Pura || {}
Pura.enableAnnouncementBar = enableAnnouncementBar

import { KlevuConfig } from '@klevu/core'

const initializeKlevu = () => {
  KlevuConfig.init({
    url: 'https://eucs30v2.ksearchnet.com/cs/v2/search',
    apiKey: Pura.environment.production
      ? import.meta.env.VITE_KLEVU_API
      : import.meta.env.VITE_KLEVU_API_STAGING,
  })
}

export { initializeKlevu }
